<div class="ziti-page-container ziti-edge-routers-container">
    <lib-list-page-header [title]="title"
                          [tabs]="tabs"
                          [showAdd]="!itemsSelected"
                          (actionClicked)="headerActionClicked($event)"></lib-list-page-header>

    <lib-data-table [tableId]="'edge-routers'"
                    [rowData]="rowData"
                    [columnDefinitions]="columnDefs"
                    (actionRequested)="tableAction($event)"
                    [startCount]="startCount"
                    [endCount]="endCount"
                    [totalCount]="totalCount"
                    [emptyMsg]="'No Edge Routers defined, Click the plus to add an Edge Router.'"
                    [filterApplied]="filterApplied"
                    [menuItems]="svc.menuItems"
                    [headerActions]="svc.tableHeaderActions"
                    [noItemsImage]="'No_Routers'"
    >
    </lib-data-table>
</div>
<lib-side-modal [(open)]="svc.sideModalOpen" [showClose]="false">
    <lib-edge-router-form
        *ngIf="svc.modalType === 'edge-router' && svc.sideModalOpen"
        [formData]="svc.selectedEdgeRouter"
        [edgeRouterRoleAttributes]="edgeRouterRoleAttributes"
        (close)="closeModal($event)"
        (dataChange)="dataChanged($event)"
    >
        <ng-content select="[slot=column-1-slot-1]" slot="column-1-slot-1"></ng-content>
        <ng-content select="[slot=column-2-slot-1]" slot="column-2-slot-1"></ng-content>
    </lib-edge-router-form>
</lib-side-modal>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
